import { useEffect } from "react"
import { useSeoContext } from "../Provider/SEOProvider"
import { SEOProps } from "./SEO"

function useSEO(seo: SEOProps) {
    const { setSeo } = useSeoContext();
    useEffect(() => {
        setSeo({ ...seo })
    }, [])
}
export default useSEO;

